import styled from '@emotion/styled'
import {Icon, type IconName} from '@ui/icon'
import {Button, Text} from '@components/ui'
import type {ComponentProps, FC} from 'react'

const Wrapper = styled(Button)`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  gap: 7px;
  height: 100%;
  width: 100%;
  padding: 10px;
`

const StyledIcon = styled(Icon)`
  color: ${({theme}) => theme.palette.backgroundSecondary};
`

const Label = styled(Text)`
  color: ${({theme}) => theme.palette.textSecondary};
`

interface Props extends ComponentProps<typeof Button> {
  iconName: IconName
  label: string
  size?: number
}

export const FooterAction: FC<Props> = ({
  iconName,
  label,
  size = 20,
  ...properties
}) => {
  return (
    <Wrapper variant="text" {...properties}>
      <StyledIcon name={iconName} width={size} height={size} />
      <Label size="bold3XS">{label}</Label>
    </Wrapper>
  )
}
