import styled from '@emotion/styled'
import {useDeviceDetection} from '@hooks/use-device-detection'
import {UnAuthorizationActionsScenario} from '@components/features/touch-footer-actions/ui/unauthorization-scenario'
import {AuthorizationActionsScenario} from '@components/features/touch-footer-actions/ui/authorization-scenario'
import {FooterActionsPages} from '@components/features/touch-footer-actions/ui/pages'
import {observer} from 'mobx-react'
import {store} from '@store/index'
import {useLocalObservable} from 'mobx-react-lite'

const Wrapper = styled.div`
  width: 100%;
  position: sticky;
  bottom: 0;
  border-top: 1px solid ${({theme}) => theme.palette.borderTertiary};
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  z-index: 4;
`

const selectIsAuthorized = () => ({
  get isAuthorized() {
    return store().user.isAuthorized
  },
})

export const TouchFooterActions = observer(() => {
  const isTouch = useDeviceDetection('touch')
  const {isAuthorized} = useLocalObservable(selectIsAuthorized)

  if (!isTouch) {
    return null
  }

  return (
    <Wrapper>
      {isAuthorized ? (
        <AuthorizationActionsScenario />
      ) : (
        <UnAuthorizationActionsScenario />
      )}
      <FooterActionsPages />
    </Wrapper>
  )
})
