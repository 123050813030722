import dynamic from 'next/dynamic'
import {Portal} from '@components/layouts'

const FooterActionsProfilePage = dynamic(() =>
  import('./profile').then((result) => result.FooterActionsProfilePage),
)

const FooterActionsMorePage = dynamic(() =>
  import('./more').then((result) => result.FooterActionsMorePage),
)

const FooterActionsHelpPage = dynamic(() =>
  import('./help').then((result) => result.FooterActionsHelpPage),
)

export const FooterActionsPages = () => {
  return (
    <>
      <Portal>
        <FooterActionsProfilePage />
      </Portal>
      <Portal>
        <FooterActionsMorePage />
      </Portal>
      <Portal>
        <FooterActionsHelpPage />
      </Portal>
    </>
  )
}
