import styled from '@emotion/styled'
import {FooterAction} from '@components/features/touch-footer-actions/ui/action'
import useTranslation from 'next-translate/useTranslation'
import {store} from '@store/index'
import {EAuthorizationModalAction} from '@store/authorization/types'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const UnAuthorizationActionsScenario = () => {
  const {t} = useTranslation('features.touch-footer-actions')

  const handleClickSearch = () => {
    store().search.setIsOpenMobileMainPage(true)
  }

  const handleClickLike = () => {
    store().authorization.setModalAction(EAuthorizationModalAction.REGISTER)
    store().authorization.setModalOpen(true)
  }

  const handleClickProfile = () => {
    store().authorization.setModalAction(EAuthorizationModalAction.LOGIN)
    store().authorization.setModalOpen(true)
  }

  const handleClickDots = () => {
    store().footerActions.pages.more.setIsOpen(true)
  }

  return (
    <Wrapper>
      <FooterAction
        onClick={handleClickSearch}
        iconName="search"
        label={t('search')}
      />
      <FooterAction
        onClick={handleClickLike}
        iconName="like"
        label={t('like')}
      />
      <FooterAction
        onClick={handleClickProfile}
        iconName="profile"
        label={t('un-authorization.profile')}
      />
      <FooterAction
        onClick={handleClickDots}
        iconName="dots"
        label={t('authorization.more')}
      />
    </Wrapper>
  )
}
